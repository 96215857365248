.home-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh !important;
  min-height: 806px;
  background-image: url("../../assets/Home/bg.jpg");
}

.icon {
  top: 30%;
  position: absolute;
  text-align: right;
  right: 0;
  width: 200px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-top: 240px;
  z-index: 900;
}
@media only screen and (min-width: 475px) {
  .icon {
    margin-bottom: 10px;
  }
}
.icon a {
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  font-weight: 1000;
  padding: 10px;
  font-size: 14px;
  font-family: "oswald", "sans-serif";
  border-radius: 50px 0px 0px 50px;
  transform: translate(150px, 0px);
  transition: all 0.5s;
  z-index: 900;
}
.icon a:hover {
  border: 0.5px solid #ff5823;
  transform: translate(50px, 0px);
}
.icon a i {
  margin-right: 25px;
  background-color: white;
  height: 40px;
  width: 40px;
  color: black;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  transition: all 0.5s;
  z-index: 900;
}
.icon a:hover i {
  transform: rotate(360deg);
}

.icon a i.fa-linkedin {
  color: #2c80d3;

  border-radius: 2px solid white;
}
.icon a i.fa-github {
  color: #fa0910;
}
.icon a i.fa-twitter {
  color: #2c80d3;
}
.linkedin {
  background-color: #1f2235;
  color: white;
}
.github {
  color: #fa0910;
}
.twitter {
  color: #53c5ff;
}
